
  import { defineComponent, onMounted } from 'vue'
  import { setCurrentPageBreadcrumbs } from '@src/core/helpers/breadcrumb'
  import { trans, transChoice } from '@src/core/helpers/translate'
  import { capitalize } from '@src/core/helpers/textUtils'

  export default defineComponent({
    name: 'TemplatesIndex',
    setup () {
      onMounted(() => {
        setCurrentPageBreadcrumbs(
          capitalize(trans('nav.aside.templates.all')),
          [
            capitalize(transChoice('global.template', 2))
          ]
        )
      })
    }
  })
